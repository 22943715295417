import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GET_INVOICE_DETAILS } from "../../graphql/queries";
import { useQuery } from "@apollo/client";

import { Loading } from "components/Loading";

import { Descriptions, Badge, Row, Col } from "antd";

import moment from "moment";

import InvoiceContainer from "./styles";

import { clearCart } from "../../helpers/common";

const statusMap = {
  Succss: "success",
  InProgress: "warning",
  Authorize: "default",
};

const InvoiceDetails = () => {
  const { t } = useTranslation();

  const { paymentID } = useParams();

  const { loading: invoiceDetailsLoading, data: invoiceDetailsData } = useQuery(
    GET_INVOICE_DETAILS,
    { variables: { paymentId: paymentID } },
  );

  if (invoiceDetailsLoading) return <Loading />;

  const transactionStatus = invoiceDetailsData?.invoiceDetails?.transactionStatus || "N/A";
  const transactionDate = invoiceDetailsData?.invoiceDetails?.transactionDate || "N/A";
  const dueValue = invoiceDetailsData?.invoiceDetails?.dueValue || "N/A";

  // clear cart if payment is successful.
  if (
    transactionStatus === "Succss" &&
    invoiceDetailsData?.invoiceDetails?.customerReference?.includes("order")
  )
    clearCart();

  const items = [
    {
      key: "1",
      label: "Invoice ID",
      children: invoiceDetailsData?.invoiceDetails?.invoiceId || "N/A",
    },
    {
      key: "2",
      label: "Invoice Status",
      children: invoiceDetailsData?.invoiceDetails?.invoiceStatus || "N/A",
    },
    {
      key: "3",
      label: "Invoice Reference",
      children: invoiceDetailsData?.invoiceDetails?.invoiceReference || "N/A",
    },
    {
      key: "4",
      label: "Transaction Date",
      children:
        transactionDate !== "N/A"
          ? moment(transactionDate).format("DD-MM-YYYY hh:mm a")
          : transactionDate,
    },
    {
      key: "5",
      label: "Payment Gateway",
      children: invoiceDetailsData?.invoiceDetails?.paymentGateway || "N/A",
    },
    {
      key: "6",
      label: "Transaction ID",
      children: invoiceDetailsData?.invoiceDetails?.transactionId || "N/A",
    },
    {
      key: "7",
      label: "Transaction Status",
      span: 2,
      children: <Badge status={statusMap[transactionStatus] || "error"} text={transactionStatus} />,
    },
    {
      key: "8",
      label: "Due Value",
      children: dueValue !== "N/A" ? dueValue + " KD" : dueValue,
    },
    {
      key: "9",
      label: "Reference ID",
      children: invoiceDetailsData?.invoiceDetails?.referenceId || "N/A",
    },
    {
      key: "10",
      label: "Track ID",
      children: invoiceDetailsData?.invoiceDetails?.trackId || "N/A",
    },
    {
      key: "11",
      label: "Payment ID",
      children: invoiceDetailsData?.invoiceDetails?.paymentId || "N/A",
    },
    {
      key: "12",
      label: "Customer Information",
      span: 3,
      children: (
        <>
          Customer Name: {invoiceDetailsData?.invoiceDetails?.customerName || "N/A"}
          <br />
          Customer Email: {invoiceDetailsData?.invoiceDetails?.customerEmail || "N/A"}
          <br />
          Customer Mobile: {invoiceDetailsData?.invoiceDetails?.customerMobile || "N/A"}
        </>
      ),
    },
    {
      key: "13",
      label: "Error",
      span: 2,
      children: invoiceDetailsData?.invoiceDetails?.error || "N/A",
    },
    {
      key: "14",
      label: "Error Code",
      children: invoiceDetailsData?.invoiceDetails?.errorCode || "N/A",
    },
  ];

  return (
    <InvoiceContainer>
      <Row
        className="mx-3 my-5"
        style={{
          justifyContent: "center",
        }}
      >
        <Col xs={24} md={20} lg={12}>
          <Descriptions
            title={t("payment.invoice.title")}
            layout="vertical"
            bordered
            items={items}
          />
        </Col>
      </Row>
    </InvoiceContainer>
  );
};

export default InvoiceDetails;
