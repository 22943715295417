import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { GET_ALBUM_PICTURES_LIST } from "../../../graphql/queries";

import { Layout, Row, Col, Typography } from "antd";

import { Loading } from "../../Loading";

import PictureAlbumContainer from "./styles";
import PictureCarousel from "./PictureCarousel";

const { Content } = Layout;
const { Title } = Typography;

const PictureAlbum = () => {
  const { t } = useTranslation();

  const { loading: albumPicturesListLoading, data: albumPicturesListData } = useQuery(
    GET_ALBUM_PICTURES_LIST,
    {
      variables: { active: true },
    },
  );

  if (albumPicturesListLoading) return <Loading />;

  return (
    <PictureAlbumContainer>
      {albumPicturesListData.albumPicturesList.length > 0 && (
        <Layout
          id="pictures-album"
          className="layout-default"
          style={{
            background: "#fff",
          }}
        >
          <Content
            style={{
              marginTop: "60px",
              marginBottom: "60px",
            }}
          >
            <Row gutter={[24, 40]} justify="space-around">
              <Col
                style={{
                  width: "500px",
                  textAlign: "center",
                }}
              >
                {/* header */}
                <Title
                  className="mb-4 mt-0"
                  style={{
                    fontSize: "45px",
                    fontWeight: "bolder",
                  }}
                >
                  {t("home.pictureAlbum.title")}
                </Title>
              </Col>
            </Row>

            {/* album pictures list */}
            {!albumPicturesListLoading && (
              <Row gutter={[24, 40]} justify="space-around">
                <PictureCarousel data={albumPicturesListData.albumPicturesList} />
              </Row>
            )}
          </Content>
        </Layout>
      )}
    </PictureAlbumContainer>
  );
};

export default PictureAlbum;
