import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Popconfirm, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const PictureAlbumFormList = ({
  inputFields,
  setInputFields,
  data,
  deletePictureAlbumMutation,
}) => {
  const { t } = useTranslation();

  const handleFormChange = (index, eventName, eventValue) => {
    const data = [...inputFields];
    data[index][eventName] = eventValue;
    setInputFields(data);
  };

  const addFields = () => {
    const newField = {
      picture: null,
      active: false,
    };
    setInputFields([...inputFields, newField]);
  };

  const removeFields = async (index) => {
    const data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  return (
    <Form.List name="pictureAlbumForm" initialValue={inputFields}>
      {(fields, { add, remove }) => {
        return fields.map((field, index) => (
          <React.Fragment key={field.key}>
            <Card
              title={t("dashboard.landingPageSettings.pictureAlbumSection.cardTitle") + (index + 1)}
              extra={
                fields.length <= 1 && !("id" in inputFields[index]) ? null : "id" in
                  inputFields[index] ? (
                  <Popconfirm
                    title={t(
                      "dashboard.landingPageSettings.pictureAlbumSection.confirmDeleteTitle",
                    )}
                    description={t(
                      "dashboard.landingPageSettings.pictureAlbumSection.confirmDeleteDescription",
                    )}
                    onConfirm={() =>
                      deletePictureAlbumMutation({
                        variables: { pictureAlbumId: parseInt(inputFields[index].id) },
                      })
                    }
                    onCancel={() => {}}
                    okText={t("dashboard.yes")}
                    cancelText={t("dashboard.no")}
                  >
                    <DeleteFilled />
                  </Popconfirm>
                ) : (
                  <DeleteFilled
                    onClick={() => {
                      removeFields(index);
                      remove(field.name);
                    }}
                  />
                )
              }
              className={index === fields.length - 1 ? "mb-4" : "mb-5"}
            >
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={
                      <>
                        {t("dashboard.landingPageSettings.pictureAlbumSection.picture")}
                        {"id" in inputFields[index] && (
                          <>
                            &nbsp;
                            <a href={data[index].picture} target="_blank" rel="noopener noreferrer">
                              (
                              {t(
                                "dashboard.landingPageSettings.pictureAlbumSection.viewCurrentPicture",
                              )}
                              )
                            </a>
                          </>
                        )}
                      </>
                    }
                    name={[index, "picture"]}
                    rules={[
                      {
                        required: !("id" in inputFields[index]),
                        message: t("auth.signUp.requiredFieldMessage"),
                      },
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      ({ getFieldValue }) => ({
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        validator(_, value) {
                          const pictureFile = inputFields[index].picture;

                          // Validate file size (1 MB)
                          if (pictureFile && pictureFile.size > 1024 * 1024) {
                            return Promise.reject(
                              new Error(t("registrationForm.stepTwo.helpText.fileSize")),
                            );
                          }

                          // Validate square image
                          if (pictureFile) {
                            return new Promise((resolve, reject) => {
                              const img = new Image();
                              img.src = URL.createObjectURL(pictureFile);

                              img.onload = () => {
                                if (img.width !== img.height)
                                  reject(
                                    new Error(
                                      t(
                                        "dashboard.landingPageSettings.pictureAlbumSection.squareImage",
                                      ),
                                    ),
                                  );
                                else resolve(); // Validation passed
                              };

                              // if the image cannot be loaded successfully (ex: invalid format)
                              img.onerror = () => {
                                reject(
                                  new Error(
                                    t(
                                      "dashboard.landingPageSettings.pictureAlbumSection.invalidImage",
                                    ),
                                  ),
                                );
                              };
                            });
                          }

                          // Return resolved promise for cases where no file is uploaded
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(event) =>
                        handleFormChange(index, "picture", event.target.files[0])
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col xs={24}>
                  <Form.Item
                    label={t("dashboard.landingPageSettings.pictureAlbumSection.active")}
                    name={[index, "active"]}
                  >
                    <Checkbox
                      checked={inputFields[index].active}
                      onChange={(event) => handleFormChange(index, "active", event.target.checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            {index === fields.length - 1 && (
              <Row gutter={[8, 8]} justify="center">
                <Col xs={24} className="mb-4">
                  <Button
                    onClick={() => {
                      addFields();
                      add();
                    }}
                    style={{ width: "100%" }}
                    icon={<PlusOutlined />}
                  />
                </Col>
              </Row>
            )}
          </React.Fragment>
        ));
      }}
    </Form.List>
  );
};
export default PictureAlbumFormList;
