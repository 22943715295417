import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

import { Button, Card, Col, Row, Table, Typography } from "antd";
import {
  DeleteOutlined,
  ShoppingOutlined,
  MinusOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import styled from "styled-components";

import useAuth from "../../auth";

import { useMutation } from "@apollo/client";
import { NEW_ORDER_MUTATION } from "../../graphql/mutations";

const { Title } = Typography;

const CartDetails = () => {
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();

  const [errorMessage, setErrorMessage] = useState(null);
  const errorMessageRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [newOrderMutation] = useMutation(NEW_ORDER_MUTATION, {
    onCompleted: (data) => {
      if (data.newOrder.success) {
        setErrorMessage(null);
        localStorage.setItem("orderID", data.newOrder.orderId);
        navigate("/checkout");
      } else {
        setErrorMessage(data.newOrder.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      const parsedCart = JSON.parse(savedCart);
      setCart(parsedCart);
      updateTotalPrice(parsedCart);
    }
  }, []);

  // Update the total price based on cart items
  const updateTotalPrice = (cartItems) => {
    const total = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
    setTotalPrice(total);
  };

  // Handle counter change (increment or decrement)
  const handleCounterChange = (index, change) => {
    const updatedCart = [...cart];
    const item = updatedCart[index];

    // Update the quantity with a minimum of 1 (no negative or zero quantity)
    item.quantity = Math.max(1, item.quantity + change);

    // Update the cart state and total price
    setCart(updatedCart);
    updateTotalPrice(updatedCart);

    // Save the updated cart to localStorage
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  // Handle removing an item from the cart
  const handleRemoveItem = (itemId) => {
    const updatedCart = cart.filter((item) => item.id !== itemId);
    setCart(updatedCart);
    updateTotalPrice(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const clearCart = () => {
    setCart([]);
    setTotalPrice(0);
    localStorage.removeItem("cart");
  };

  // Columns for the table
  const columns = [
    {
      dataIndex: "image",
      key: "image",
      render: (text, obj) => {
        const name = obj["name" + (currentLanguage === "en" ? "En" : "Ar")];
        return <img src={obj.picture} alt={name} style={{ width: 100, height: 100 }} />;
      },
    },
    {
      dataIndex: "name",
      key: "name",
      render: (text, obj) => obj["name" + (currentLanguage === "en" ? "En" : "Ar")],
    },
    {
      dataIndex: "price",
      key: "price",
      render: (text) => (
        <span style={{ fontFamily: '"Rubik", sans-serif' }}>{text.toFixed(2) + " KD"}</span>
      ),
    },
    {
      dataIndex: "quantity",
      key: "quantity",
      render: (text, obj, index) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <StyledButton
            onClick={() => handleCounterChange(index, -1)}
            disabled={text === 1} // Disable if counter is 1
            icon={<MinusOutlined />}
          />
          <span style={{ margin: "0 10px", fontFamily: '"Rubik", sans-serif' }}>{text}</span>
          <StyledButton onClick={() => handleCounterChange(index, 1)} icon={<PlusOutlined />} />
        </div>
      ),
    },
    {
      dataIndex: "total",
      key: "total",
      render: (text, obj) => (
        <span style={{ fontFamily: '"Rubik", sans-serif' }}>
          {(obj.price * obj.quantity).toFixed(2) + " KD"}
        </span>
      ),
    },
    {
      key: "action",
      render: (text, obj) => (
        <StyledButton icon={<DeleteOutlined />} onClick={() => handleRemoveItem(obj.id)} danger />
      ),
    },
  ];

  const handleProceedToPayment = () => {
    setSubmitting(true);
    const data = cart.map((obj) => {
      return { productId: parseInt(obj.id), quantity: obj.quantity };
    });
    newOrderMutation({
      variables: { productsList: data },
    });
  };

  return (
    <Row gutter={24}>
      <Col xs={24} lg={16}>
        <Title level={2}>{t("cart.title")}</Title>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={cart}
          pagination={false}
          showHeader={false}
          scroll={{ x: true }}
        />
        <Row gutter={10} style={{ marginTop: 20 }}>
          <Col>
            <HashLink to="/#products">
              <Button type="link" icon={<ShoppingOutlined />} style={{ padding: "4px" }}>
                {t("cart.continueShoppingButton")}
              </Button>
            </HashLink>
          </Col>

          <Col>
            <Button
              type="link"
              danger
              onClick={() => clearCart()}
              icon={<DeleteOutlined />}
              style={{ padding: "4px" }}
            >
              {t("cart.clearCartButton")}
            </Button>
          </Col>
        </Row>
      </Col>

      <Col xs={24} lg={8} className="mt-4 mt-lg-0">
        <Card>
          <div ref={errorMessageRef}></div> {/* Error message ref */}
          {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
          <p>
            <span className="fw-bold">{t("cart.totalPrice")}:&nbsp;</span>
            <span style={{ fontFamily: '"Rubik", sans-serif' }}>{totalPrice.toFixed(2)} KD</span>
          </p>
          <Button
            type="primary"
            block
            onClick={() => handleProceedToPayment()}
            loading={submitting}
            disabled={!isAuthenticated || cart.length === 0 || totalPrice === 0 || submitting}
          >
            {t("cart.proceedToPaymentButton")}
          </Button>
          {!isAuthenticated && (
            <p
              style={{ color: "#ff4d4f", marginTop: "4px", display: "flex", alignItems: "center" }}
            >
              <ExclamationCircleOutlined
                style={currentLanguage === "en" ? { marginRight: "4px" } : { marginLeft: "4px" }}
              />
              {t("cart.loginToProceedMessage")}
            </p>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default CartDetails;

const StyledButton = styled(Button)`
  svg {
    margin: 0;
  }
`;
