import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Modal, DatePicker, Row, Col, Button, Form, Input } from "antd";

import { NEW_ABSENCE_REQUEST_MUTATION } from "graphql/mutations";
import { useMutation } from "@apollo/client";

const AbsenceRequestModal = ({ isModalOpen, handleOk, handleCancel, registrationId }) => {
  const { t } = useTranslation();

  const [date, setDate] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const errorMessageRef = useRef(null);
  const successMessageRef = useRef(null);

  const [newAbsenceRequestMutation] = useMutation(NEW_ABSENCE_REQUEST_MUTATION, {
    onCompleted: (data) => {
      if (data.newAbsenceRequest.success) {
        setErrorMessage(null);
        setSuccessMessage(
          t("auth.participantProfile.historyCard.absenceRequestModal.submittedSuccessfullyMessage"),
        );
        scrollToSuccess();
        setTimeout(() => {
          setSuccessMessage(null);
          handleCancel();
        }, 5000); // Clear success message after 5 seconds
      } else {
        setErrorMessage(data.newAbsenceRequest.errors[0]);
        setSuccessMessage(null);
        scrollToError();
      }
    },
  });

  const onFinish = (values) => {
    newAbsenceRequestMutation({
      variables: {
        ...values,
        date,
        registrationId,
      },
    });
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToSuccess = () => {
    if (successMessageRef.current) {
      successMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Modal
      title={
        <span style={{ fontSize: "16px" }}>
          {t("auth.participantProfile.historyCard.absenceRequestModal.title")}
          <p className="mt-3" style={{ fontSize: "14px" }}>
            {t("auth.participantProfile.historyCard.absenceRequestModal.subTitle")}
          </p>
        </span>
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={600}
      footer={null}
    >
      <Form onFinish={onFinish} layout="vertical" className="row-col" scrollToFirstError={true}>
        <div ref={errorMessageRef}></div> {/* Error message ref */}
        {errorMessage && <p className="english-field text-danger">{errorMessage}</p>}
        <div ref={successMessageRef}></div> {/* Success message ref */}
        {successMessage && <p className="text-success">{successMessage}</p>}
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Form.Item
              label={t("auth.participantProfile.historyCard.absenceRequestModal.dateField")}
              name="date"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                className="english-field"
                onChange={(dateObj, dateString) => {
                  // dateString will be in the format "YYYY-MM-DD"
                  setDate(dateString);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Form.Item
              label={t("auth.participantProfile.historyCard.absenceRequestModal.reasonField")}
              name="reason"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                {t("auth.participantProfile.historyCard.absenceRequestModal.submitBtn")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AbsenceRequestModal;
