import { Button, Col, Layout, Modal, Row, Typography, message } from "antd";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import ContactUsForm from "./ContactUsForm";

const { Title, Text } = Typography;
const { Content } = Layout;

const ContactUsSection = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [shouldShowAlert, setShouldShowAlert] = useState(false);
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);

  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const handleContactUsClick = () => {
    setIsContactUsModalOpen(true);
  };

  const handleCloseContactUsModal = () => {
    setIsContactUsModalOpen(false);
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: t("home.contactUs.form.successMessage"),
      duration: 5,
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: t("home.contactUs.form.errorMessage"),
      duration: 5,
    });
  };

  const triggerMessage = () => {
    if (isRequestSuccessful) {
      success();
    } else {
      error();
    }
  };

  return (
    <div id="contact-us">
      {shouldShowAlert && contextHolder}

      <Content
        style={{
          marginTop: "60px",
          paddingBottom: "60px",
          padding: "60px 48px",
          backgroundColor: "rgb(242, 245, 249)",
        }}
      >
        <Row gutter={[24, 40]} justify="space-between" align="middle">
          <Col lg={18} md={12} xs={24}>
            <Title
              className="mb-4 mt-0"
              style={{
                fontSize: "45px",
                fontWeight: "bolder",
              }}
            >
              {t("home.contactUs.title")}
            </Title>
            <Text
              className="text-center"
              style={{
                fontSize: "16px",
                color: "rgb(87,91,103)",
              }}
            >
              {t("home.contactUs.getToKnowTheDedicatedTeam")}
            </Text>
          </Col>

          <Col lg={6} md={12} xs={24}>
            <Text
              style={{
                fontSize: "16px",
                color: "rgb(87,91,103)",
                marginLeft: currentLanguage === "ar" ? "8px" : "0",
                marginRight: currentLanguage === "ar" ? "0" : "8px",
              }}
            >
              {t("home.contactUs.wantPersonalizedHelp")}
            </Text>

            <Button size="large" type="primary" onClick={handleContactUsClick}>
              {t("home.contactUs.contactUsNow")}
            </Button>
          </Col>
        </Row>
      </Content>

      <Modal
        title={t("home.contactUs.title")}
        open={isContactUsModalOpen}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCloseContactUsModal}
        width={600}
        footer={null}
      >
        <ContactUsForm
          handleClose={handleCloseContactUsModal}
          setIsRequestSuccessful={setIsRequestSuccessful}
          setShouldShowAlert={setShouldShowAlert}
          triggerRequestStatusMessage={triggerMessage}
        />
      </Modal>
    </div>
  );
};

export default ContactUsSection;
