import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export const formatTime = (timeString, language) => {
  const momentTime = moment(timeString, "HH:mm:ss");
  if (language === "ar" && momentTime.format("A") === "AM")
    return momentTime.format("hh:mm صباحاً");
  else if (language === "ar" && momentTime.format("A") === "PM")
    return momentTime.format("hh:mm مساءً");
  else return momentTime.format("hh:mm A");
};

export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    // Check if geolocation is supported by the user's browser
    if (navigator.geolocation) {
      // Get the current user's location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude, error: null });
        },
        // If there was an error getting the user's location
        (error) => {
          let errorMessage;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = "Error | User denied the request for Geolocation.";
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = "Error | Location information is unavailable.";
              break;
            case error.TIMEOUT:
              errorMessage = "Error | The request to get user location timed out.";
              break;
            case error.UNKNOWN_ERROR:
            default:
              errorMessage = "Error | An unknown error occurred.";
              break;
          }
          reject({ latitude: null, longitude: null, error: errorMessage });
        },
      );
    } else {
      reject({
        latitude: null,
        longitude: null,
        error: "Error | Geolocation is not supported.",
      });
    }
  });
};

// Retrieve or create a unique device ID
export const getDeviceId = () => {
  let deviceId = localStorage.getItem("device_id");
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem("device_id", deviceId);
  }
  return deviceId;
};

export const clearCart = () => {
  localStorage.removeItem("cart");
  localStorage.removeItem("orderID");
};
