import { useQuery } from "@apollo/client";
import { Col, Flex, Row, Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import SearchBar from "components/inputs/SearchBar";
import { GET_REGISTRATIONS } from "graphql/registrations/queries";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetRegistrations } from "types/Registration";
import SendWhatsAppMessageModal from "components/Dashboard/Registrations/SendWhatsAppMessageModal";
import RegistrationsTable from "../components/Dashboard/Registrations";
import FilterModal from "../components/Dashboard/Registrations/FilterModal";
import TshirtSizeCounters from "components/Dashboard/Registrations/TshirtSizeCounters";

const RegistrationsDashboardPage = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filterQuery, setFilterQuery] = useState<string>("");

  const [isSendWhatsAppModalOpen, setSendWhatAppModalOpen] = useState<boolean>(false);
  const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);

  const { data, loading, refetch } = useQuery<GetRegistrations>(GET_REGISTRATIONS, {
    variables: { filters: filterQuery, search: searchQuery },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <Flex
        vertical
        gap="middle"
        style={{
          backgroundColor: "white",
          padding: 24,
        }}
      >
        <Row className="searchbar-row">
          <Col xs={20} lg={14}>
            <SearchBar
              placeholder={t("dashboard.searchPlaceholder")}
              value={searchQuery}
              onChange={handleChange}
            />
          </Col>

          <Col xs={4} lg={10} style={{ textAlign: currentLanguage === "ar" ? "left" : "right" }}>
            <Button
              type="primary"
              shape="circle"
              icon={<FilterOutlined />}
              className="filter-btn"
              onClick={() => setFilterModalOpen(true)}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <RegistrationsTable
              data={data?.registrations || []}
              loading={loading}
              refetchData={refetch}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <TshirtSizeCounters
              searchQuery={searchQuery}
              filterQuery={filterQuery}
              registrationsDataLoading={loading}
            />
          </Col>
        </Row>
      </Flex>

      <SendWhatsAppMessageModal
        isModalOpen={isSendWhatsAppModalOpen}
        handleOk={() => setSendWhatAppModalOpen(false)}
        handleCancel={() => setSendWhatAppModalOpen(false)}
      />

      <FilterModal
        isModalOpen={isFilterModalOpen}
        handleOk={() => setFilterModalOpen(false)}
        handleCancel={() => setFilterModalOpen(false)}
        setFilterQuery={setFilterQuery}
      />
    </>
  );
};

export default RegistrationsDashboardPage;
