import { useTranslation } from "react-i18next";

import { Table, Tag, Button, Popconfirm } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import moment from "moment";

import { UPDATE_ABSENCE_REQUEST_STATUS_MUTATION } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";

const AbsenceRequestsTable = ({ data, loading, refetchData }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const uniqueCompanyNames = Array.from(
    new Set(
      data.map(
        (item) =>
          item.registration.assignedJobOpportunity?.companyRequest?.[
            `companyName${currentLanguage === "en" ? "En" : "Ar"}`
          ],
      ),
    ),
  )
    .filter((name) => !!name)
    .sort((a, b) => a.localeCompare(b));

  const uniqueJobTitles = Array.from(
    new Set(data.map((item) => item.registration.assignedJobOpportunity?.jobTitle)),
  )
    .filter((title) => !!title)
    .sort((a, b) => a.localeCompare(b));

  const [updateAbsenceRequestMutation] = useMutation(UPDATE_ABSENCE_REQUEST_STATUS_MUTATION, {
    onCompleted: () => {
      refetchData();
    },
  });

  const confirmAbsenceRequestStatus = (absenceRequestID, status) => {
    updateAbsenceRequestMutation({
      variables: { absenceRequestId: absenceRequestID, status },
    });
  };

  const cancel = () => {
    // You can handle cancel logic here if needed
    // For example, you might not need to do anything on cancel
  };

  const columns = [
    {
      title: t("dashboard.participantID"),
      key: "participantId",
      sorter: (a, b) =>
        a.registration.participantProfile.participantId.localeCompare(
          b.registration.participantProfile.participantId,
        ),
      render: (obj) => (
        <span className="english-field">{obj.registration.participantProfile.participantId}</span>
      ),
    },
    {
      title: t("dashboard.participantName"),
      key: "participantName",
      sorter: (a, b) =>
        a.registration.participantProfile.fullNameEn.length -
        b.registration.participantProfile.fullNameEn.length,
      render: (obj) =>
        obj.registration.participantProfile["fullName" + (currentLanguage === "en" ? "En" : "Ar")],
    },
    {
      title: t("dashboard.mobileNumber"),
      key: "mobileNumber",
      render: (obj) => (
        <span style={{ unicodeBidi: "embed", direction: "ltr" }}>
          {obj.registration.participantProfile.mobileNumber}
        </span>
      ),
    },
    {
      title: t("dashboard.email"),
      key: "email",
      sorter: (a, b) =>
        a.registration.participantProfile.email.length -
        b.registration.participantProfile.email.length,
      render: (obj) => (
        <span className="english-field">{obj.registration.participantProfile.email}</span>
      ),
    },
    {
      title: t("dashboard.attendanceTable.date"),
      key: "date",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: t("dashboard.absenceRequestsTable.reason"),
      key: "reason",
      dataIndex: "reason",
    },
    {
      title: t("dashboard.status"),
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <span>
          <Tag color={getStatusColor(status)} key={status}>
            {t(`dashboard.${status.toLowerCase()}`).toUpperCase()}
          </Tag>
        </span>
      ),
    },
    {
      title: t("dashboard.absenceRequestsTable.requestedAt"),
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (createdAt) => moment(createdAt).format("DD-MM-YYYY"),
    },
    {
      title: t("dashboard.assignedCompanyName"),
      key: "assignedCompanyName",
      filters: [
        { text: "-", value: "-" },
        ...uniqueCompanyNames.map((name) => ({
          text: name,
          value: name,
        })),
      ],
      onFilter: (value, record) => {
        const companyName =
          record.registration.assignedJobOpportunity?.companyRequest?.[
            `companyName${currentLanguage === "en" ? "En" : "Ar"}`
          ];
        return value === "-" ? !companyName : companyName === value;
      },
      render: (obj) =>
        obj.registration.assignedJobOpportunity?.companyRequest?.[
          `companyName${currentLanguage === "en" ? "En" : "Ar"}`
        ] || "-",
    },
    {
      title: t("dashboard.assignedJobTitle"),
      key: "jobTitle",
      filters: [
        { text: "-", value: "-" },
        ...uniqueJobTitles.map((title) => ({ text: title, value: title })),
      ],
      onFilter: (value, record) => {
        const jobTitle = record.registration.assignedJobOpportunity?.jobTitle;
        return value === "-" ? !jobTitle : jobTitle === value;
      },
      render: (obj) => obj.registration.assignedJobOpportunity?.jobTitle || "-",
    },
    {
      title: t("dashboard.requestsTable.actions"),
      key: "actions",
      render: (obj) =>
        obj.status === "Pending" ? (
          <>
            <Popconfirm
              title={t("dashboard.absenceRequestsTable.approveAbsenceRequestTitle")}
              description={t("dashboard.absenceRequestsTable.approveAbsenceRequestDescription")}
              onConfirm={() => confirmAbsenceRequestStatus(obj.id, "Approved")}
              onCancel={cancel}
              okText={t("dashboard.yes")}
              cancelText={t("dashboard.no")}
            >
              <Button type="text" icon={<CheckCircleOutlined style={{ color: "#389e0d" }} />} />
            </Popconfirm>

            <Popconfirm
              title={t("dashboard.absenceRequestsTable.rejectAbsenceRequestTitle")}
              description={t("dashboard.absenceRequestsTable.rejectAbsenceRequestDescription")}
              onConfirm={() => confirmAbsenceRequestStatus(obj.id, "Rejected")}
              onCancel={cancel}
              okText={t("dashboard.yes")}
              cancelText={t("dashboard.no")}
            >
              <Button type="text" icon={<CloseCircleOutlined style={{ color: "#d4380d" }} />} />
            </Popconfirm>
          </>
        ) : (
          "-"
        ),
    },
  ];

  return (
    <div style={{ overflowX: "clip" }}>
      <Table
        columns={columns}
        pagination={{ position: ["bottomRight"] }}
        dataSource={data.map((item) => ({ ...item, key: item.id }))}
        scroll={{ x: true }}
        loading={loading}
      />
    </div>
  );
};

export default AbsenceRequestsTable;

const getStatusColor = (status) => {
  if (status === "Approved") return "green";
  else if (status === "Rejected") return "volcano";
  else if (status === "Pending") return "gold";
};
