import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, Carousel, Button } from "antd";
import { LeftOutlined, RightOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";

const { Meta } = Card;

const ProductCarousel = ({ data }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [counters, setCounters] = useState(data.map(() => 1)); // Initialize all counters to 1
  const [cart, setCart] = useState([]); // State for the cart

  // Retrieve cart from localStorage on component load and update counters accordingly
  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      const parsedCart = JSON.parse(savedCart);
      setCart(parsedCart);

      // Update counters based on items in the cart
      const updatedCounters = data.map((item) => {
        const cartItem = parsedCart.find((cartItem) => cartItem.id === item.id);
        return cartItem ? cartItem.quantity : 1; // Use cart quantity if exists, else default to 1
      });
      setCounters(updatedCounters);
    }
  }, [data]);

  // Save the cart to localStorage whenever it updates
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const handleAddOrUpdateCart = (index) => {
    const selectedItem = { ...data[index], quantity: counters[index] };

    setCart((prevCart) => {
      const existingIndex = prevCart.findIndex((item) => item.id === selectedItem.id);

      if (existingIndex !== -1) {
        // Update the quantity if the item already exists in the cart
        const updatedCart = [...prevCart];
        updatedCart[existingIndex].quantity = selectedItem.quantity;
        return updatedCart;
      }
      // Add the new item to the cart
      else return [...prevCart, selectedItem];
    });
  };

  const handleCounterChange = (index, increment) => {
    setCounters((prevCounters) =>
      prevCounters.map((count, i) => (i === index ? Math.max(1, count + increment) : count)),
    );
  };

  const customPrevArrow = (
    <div>
      <LeftOutlined />
    </div>
  );

  const customNextArrow = (
    <div>
      <RightOutlined />
    </div>
  );

  return (
    <Carousel
      arrows
      prevArrow={customPrevArrow}
      nextArrow={customNextArrow}
      dots={false}
      autoplay={false}
      responsive={[
        {
          breakpoint: 992, // For iPads and tablets
          settings: {
            slidesToShow: data.length < 2 ? data.length : 2, // Show 2 images at a time
          },
        },
        {
          breakpoint: 768, // For mobile devices
          settings: {
            slidesToShow: 1, // Show 1 image at a time
          },
        },
      ]}
      slidesToShow={data.length < 3 ? data.length : 3} // Default for larger screens
      slidesToScroll={1}
    >
      {data.map((obj, index) => {
        const name = obj["name" + (currentLanguage === "en" ? "En" : "Ar")];
        const description = obj["description" + (currentLanguage === "en" ? "En" : "Ar")];

        // Check if the item is already in the cart
        const isInCart = cart.some((item) => item.id === obj.id);

        return (
          <div className="w-auto px-2 px-md-5" key={obj.id}>
            <Card
              style={{
                width: 300,
              }}
              cover={<img alt={name} src={obj.picture} />}
              actions={[
                <div key="counter">
                  <Button
                    onClick={() => handleCounterChange(index, -1)}
                    disabled={counters[index] === 1} // Disable if counter is 1
                    icon={<MinusOutlined />}
                  />
                  <span style={{ margin: "0 10px", fontFamily: '"Rubik", sans-serif' }}>
                    {counters[index]}
                  </span>
                  <Button onClick={() => handleCounterChange(index, 1)} icon={<PlusOutlined />} />
                </div>,
                <Button
                  key="addOrUpdate"
                  type="primary"
                  onClick={() => handleAddOrUpdateCart(index)}
                >
                  {isInCart
                    ? t("home.products.updateCartButton")
                    : t("home.products.addToCartButton")}
                </Button>,
              ]}
            >
              <Meta title={name} description={description} />
              <Row>
                <Col span={24} style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                  {t("home.products.price")}:&nbsp;
                  <span style={{ fontFamily: '"Rubik", sans-serif' }}>
                    {obj.price.toFixed(2)} KD
                  </span>
                </Col>
              </Row>
            </Card>
          </div>
        );
      })}
    </Carousel>
  );
};

export default ProductCarousel;
