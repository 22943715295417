import { gql } from "@apollo/client";

const HERO_SECTION_FIELDS = gql`
  fragment HeroTypeFields on HeroType {
    titleEn
    titleAr
    missionEn
    missionAr
    visionEn
    visionAr
    image
  }
`;

export const UPDATE_HERO_SECTION_MUTATION = gql`
  ${HERO_SECTION_FIELDS}
  mutation updateHeroSection(
    $titleEn: String
    $titleAr: String
    $missionEn: String
    $missionAr: String
    $visionEn: String
    $visionAr: String
    $image: Upload
  ) {
    updateHeroSection(
      titleEn: $titleEn
      titleAr: $titleAr
      missionEn: $missionEn
      missionAr: $missionAr
      visionEn: $visionEn
      visionAr: $visionAr
      image: $image
    ) {
      hero {
        ...HeroTypeFields
      }
    }
  }
`;

const ABOUT_SECTION_FIELDS = gql`
  fragment AboutTypeFields on AboutType {
    id
    ourStoryEn
    ourStoryAr
    ourStoryImage

    ourProgramEn
    ourProgramAr

    ourTeamEn
    ourTeamAr
  }
`;

export const UPDATE_ABOUT_SECTION_MUTATION = gql`
  ${ABOUT_SECTION_FIELDS}
  mutation updateAboutSection(
    $ourStoryEn: String!
    $ourStoryAr: String!
    $ourStoryImage: Upload
    $ourProgramEn: String!
    $ourProgramAr: String!
    $ourTeamEn: String!
    $ourTeamAr: String!
  ) {
    updateAboutSection(
      ourStoryEn: $ourStoryEn
      ourStoryAr: $ourStoryAr
      ourStoryImage: $ourStoryImage
      ourProgramEn: $ourProgramEn
      ourProgramAr: $ourProgramAr
      ourTeamEn: $ourTeamEn
      ourTeamAr: $ourTeamAr
    ) {
      about {
        ...AboutTypeFields
      }
    }
  }
`;

export const UPDATE_FOOTER_SECTION_MUTATION = gql`
  mutation updateFooterSection(
    $email: String
    $briefAr: String
    $briefEn: String
    $whatsappNumber: String
  ) {
    updateFooterSection(
      email: $email
      briefAr: $briefAr
      briefEn: $briefEn
      whatsappNumber: $whatsappNumber
    ) {
      footer {
        email
        briefAr
        briefEn
        whatsappNumber
      }
    }
  }
`;

export const UPDATE_SOCIAL_MEDIA_MUTATION = gql`
  mutation updateSocialMedia($socialMedia: [SocialMediaInput!]!) {
    updateSocialMedia(socialMedia: $socialMedia) {
      success
      errors
    }
  }
`;

export const ADD_OR_UPDATE_PARTICIPATING_ENTITIES_MUTATION = gql`
  mutation addOrUpdateParticipatingEntity(
    $participatingEntitiesList: [ParticipatingEntityInput!]!
  ) {
    addOrUpdateParticipatingEntity(participatingEntitiesList: $participatingEntitiesList) {
      success
      errors
    }
  }
`;

export const ADD_OR_UPDATE_SPONSORS_MUTATION = gql`
  mutation addOrUpdateSponsor($sponsorsList: [SponsorInput]!) {
    addOrUpdateSponsor(sponsorsList: $sponsorsList) {
      success
      errors
    }
  }
`;

export const ADD_OR_UPDATE_TEAM_MEMBERS_MUTATION = gql`
  mutation addOrUpdateTeamMember($teamMembersList: [TeamMemberInput!]!) {
    addOrUpdateTeamMember(teamMembersList: $teamMembersList) {
      success
      errors
    }
  }
`;

export const ADD_OR_UPDATE_OUR_VALUES_MUTATION = gql`
  mutation addOrUpdateOurValuePoint($ourValuePointsList: [OurValuePointInput]!) {
    addOrUpdateOurValuePoint(ourValuePointsList: $ourValuePointsList) {
      success
      errors
    }
  }
`;

export const ADD_OR_UPDATE_OUR_STORY_STATISTICAL_ITEM_MUTATION = gql`
  mutation addOrUpdateOurStoryStatisticalItem(
    $statisticalItemsList: [OurStoryStatisticalItemInput!]!
  ) {
    addOrUpdateOurStoryStatisticalItem(statisticalItemsList: $statisticalItemsList) {
      success
      errors
    }
  }
`;

export const ADD_OR_UPDATE_PICTURE_ALBUM_MUTATION = gql`
  mutation addOrUpdatePictureAlbum($pictureAlbum: [PictureAlbumInput]!) {
    addOrUpdatePictureAlbum(pictureAlbum: $pictureAlbum) {
      success
      errors
    }
  }
`;

export const DELETE_PICTURE_ALBUM_MUTATION = gql`
  mutation deletePictureAlbum($pictureAlbumId: Int!) {
    deletePictureAlbum(pictureAlbumId: $pictureAlbumId) {
      success
      errors
    }
  }
`;

export const ADD_OR_UPDATE_PRODUCTS_MUTATION = gql`
  mutation addOrUpdateProduct($productsList: [ProductInput]!) {
    addOrUpdateProduct(productsList: $productsList) {
      success
      errors
    }
  }
`;
