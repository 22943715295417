import { Carousel } from "antd";

const PictureCarousel = ({ data }) => {
  const dataLength = data.length;

  return (
    <Carousel
      dots={false}
      autoplay
      responsive={[
        {
          breakpoint: 992, // For iPads and tablets
          settings: {
            slidesToShow: dataLength < 3 ? dataLength : 3, // Show 3 images at a time
          },
        },
        {
          breakpoint: 768, // For mobile devices
          settings: {
            slidesToShow: 1, // Show 1 image at a time
          },
        },
      ]}
      slidesToShow={data.length < 4 ? data.length : 4} // Default for larger screens
    >
      {data.map((obj) => (
        <div className="w-auto px-2 px-md-5" key={obj.id}>
          <img src={obj.picture} alt={obj.title} className="carousel-image" />
        </div>
      ))}
    </Carousel>
  );
};

export default PictureCarousel;
