import { MenuOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Badge, Button, Drawer, Layout } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import RightMenu from "./RightMenu";
import NavbarStyles from "./styles";

// Custom hook to sync cart state with localStorage
const useCart = () => {
  const [cartItems, setCartItems] = useState(0);

  const updateCartState = () => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      const parsedCart = JSON.parse(savedCart);
      const totalItems = parsedCart.reduce((sum, item) => sum + item.quantity, 0);
      setCartItems(totalItems);
    }
  };

  useEffect(() => {
    // Initialize cart state
    updateCartState();

    // Listen for changes in localStorage (from other tabs/windows)
    const handleStorageChange = () => {
      updateCartState();
    };

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return cartItems;
};

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const cartItems = useCart(); // Use custom hook to get the current cart items

  const showDrawer = () => {
    setOpen(!open);
  };

  // auto-close the mobile drawer when a path is selected
  const { pathname: location } = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <NavbarStyles>
      <nav className="navbar">
        <Layout>
          <Layout.Header className="nav-header px-2 px-md-0">
            <Link to="/" className="logo">
              <img className="brand-font" src="/logo.svg" alt="Waed Logo" width="80" />
            </Link>

            <RightMenu mode="horizontal" />

            <Button className="menuButton" type="text" onClick={showDrawer}>
              <MenuOutlined />
            </Button>

            <Drawer
              title={<img className="brand-font" src="/logo.svg" alt="Waed Logo" width="80" />}
              placement="right"
              closable={true}
              onClose={showDrawer}
              open={open}
              style={{ zIndex: 99999 }}
            >
              <RightMenu mode="inline" />
            </Drawer>

            {/* Add Cart Button */}
            <Link
              to="/cart"
              className="cart-button"
              style={{
                width: "30px",
                height: "60px",
              }}
            >
              <Badge
                count={cartItems}
                size="small"
                offset={[10, 0]}
                style={{
                  fontFamily: '"Rubik", sans-serif',
                }}
              >
                <StyledCartButton
                  type="text"
                  icon={
                    <ShoppingCartOutlined
                      style={{
                        fontSize: "1.5rem",
                      }}
                    />
                  }
                />
              </Badge>
            </Link>
          </Layout.Header>
        </Layout>
      </nav>
    </NavbarStyles>
  );
};

export default Navbar;

const StyledCartButton = styled(Button)`
  width: 30px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border-radius: none;

  &:hover {
    background: none !important;
  }
`;
