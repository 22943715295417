import { auth } from "configs/firebase";
import { getDeviceId } from "helpers/common";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_DEVICE_ID_MUTATION, VERIFY_DEVICE_ID_MUTATION } from "../graphql/mutations";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  const [deviceIdVerifiedLoading, setDeviceIdVerifiedLoading] = useState(true);
  const [deviceIdVerified, setDeviceIdVerified] = useState(false);

  const [verifyDeviceIdMutation] = useMutation(VERIFY_DEVICE_ID_MUTATION, {
    onCompleted: (data) => {
      if (data.verifyDeviceId.success) {
        setDeviceIdVerified(data.verifyDeviceId.verified);
        setDeviceIdVerifiedLoading(false);
      }
    },
  });

  const [updateDeviceIdMutation] = useMutation(UPDATE_DEVICE_ID_MUTATION);

  const hasUsedSocialAuth = user?.uid;

  const logout = () => {
    Cookies.remove("token");
    setUser(null);

    if (hasUsedSocialAuth) {
      auth.signOut();
    }
  };

  useEffect(() => {
    const verifyAndSetUser = async () => {
      const token = Cookies.get("token");
      if (token) {
        try {
          // Logout the user if current device ID does not match the one in the database.
          await verifyDeviceIdMutation({ variables: { deviceId: getDeviceId() } });

          if (!deviceIdVerifiedLoading && !deviceIdVerified) {
            await logout();
            navigate("/sign-in");
            return; // Stop execution after logout
          }

          // Logout the user if token is expired.
          const currentTime = Date.now() / 1000;
          const { exp } = jwtDecode(token);

          if (exp < currentTime) {
            await logout();
            navigate("/sign-in");
            return; // Stop execution after logout
          }

          // Set the user if the token is valid.
          const { email, user_id, user_type, uid, is_verified } = jwtDecode(token);
          setUser({ email, userID: user_id, userType: user_type, uid, isVerified: is_verified });
          setUserLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("verifyAndSetUser Error: ", error);
        }
      } else {
        // No token: clear user state
        setUser(null);
        setUserLoading(false);
      }
    };

    verifyAndSetUser();
  }, [deviceIdVerifiedLoading, deviceIdVerified, verifyDeviceIdMutation, navigate]);

  const signIn = ({ token }) => {
    Cookies.set("token", token, { expires: 1 });

    updateDeviceIdMutation({ variables: { deviceId: getDeviceId() } }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error("updateDeviceIdMutation Error: ", error);
    });

    const { email, user_id, user_type, uid, is_verified } = jwtDecode(token);
    setUser({ email, userID: user_id, userType: user_type, uid, isVerified: is_verified });
    setUserLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        userLoading,
        signIn,
        logout,
        setUser,
        hasUsedSocialAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
