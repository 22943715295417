import { useQuery } from "@apollo/client";
import AboutUsSection from "components/Home/AboutUsSection";
import ContactUsSection from "components/Home/ContactUsSection";
import OurProgramsSection from "components/Home/OurProgramsSection";
import { GET_ABOUT_SECTION_DETAILS } from "graphql/queries";
import { GetAboutData } from "types/Home";
import Hero from "../components/Home/Hero";
import ParticipatingEntities from "../components/Home/ParticipatingEntities";
import Sponsors from "../components/Home/Sponsors";
import Team from "../components/Home/Team";
import PictureAlbum from "../components/Home/PictureAlbum";
import Products from "components/Home/Products";

const Home = () => {
  const { data: aboutData, loading: aboutLoading } =
    useQuery<GetAboutData>(GET_ABOUT_SECTION_DETAILS);

  return (
    <div className="layout-default">
      <Hero />
      <ParticipatingEntities />
      <AboutUsSection data={aboutData} loading={aboutLoading} />
      <OurProgramsSection aboutData={aboutData} aboutDataLoading={aboutLoading} />
      <Team />
      <Sponsors />
      <PictureAlbum />
      <Products />
      <ContactUsSection />
    </div>
  );
};

export default Home;
