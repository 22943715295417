import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { PRODUCTS_LIST } from "../../../graphql/queries";

import { Layout, Row, Col, Typography } from "antd";

import { Loading } from "../../Loading";
import ProductCarousel from "./ProductCarousel";

import ProductsListContainer from "./styles";

const { Title } = Typography;
const { Content } = Layout;

const Products = () => {
  const { t } = useTranslation();

  const { loading: productsListLoading, data: productsListData } = useQuery(PRODUCTS_LIST, {
    variables: { active: true },
  });

  if (productsListLoading) return <Loading />;

  const dataLength = productsListData?.productsList?.length;

  return (
    dataLength > 0 && (
      <ProductsListContainer>
        <Layout className="layout-default">
          <Content id="products">
            <Row gutter={[24, 40]} justify="space-around">
              <Col
                style={{
                  width: "500px",
                  textAlign: "center",
                }}
              >
                {/* header */}
                <Title
                  className="mb-4 mt-0"
                  style={{
                    fontSize: "45px",
                    fontWeight: "bolder",
                  }}
                >
                  {t("home.products.title")}
                </Title>
              </Col>
            </Row>

            <Row gutter={[24, 40]} justify="space-around">
              <ProductCarousel data={productsListData.productsList} />
            </Row>
          </Content>
        </Layout>
      </ProductsListContainer>
    )
  );
};

export default Products;
